<template>
  <span>
    <v-subheader>Witholding Tax Payments</v-subheader>

    <v-list dense>


           <v-list-item
               color="red"
               v-for="payment in result.items"
               :key="payment.id"
               class="mb-2 text-decoration-none"
               :to="`/expense/taxpayment/withholding/${payment.uuid}`"
           >

        <v-list-item-avatar rounded="lg" size="60" color="red lighten-5">
            <v-icon color="red darken-1">mdi-cash-minus</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{
              payment.amount | toMoney | currency_symbol
            }} - {{ payment.withholding_account.account_name }}</v-list-item-title>
          <v-list-item-subtitle>Date: {{ payment.date | humanDate }}</v-list-item-subtitle>
          <v-list-item-subtitle>Paid from: {{ payment.payment_account.account_name }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action-text>
          <v-icon color="grey lighten-2" small>mdi-chevron-right</v-icon>
        </v-list-item-action-text>

      </v-list-item>

    </v-list>
  </span>
</template>

<script>
export default {
  props: ["result"],
};
</script>
