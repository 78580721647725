<template>
  <v-card>
    <v-card-title>Create a new customer <v-spacer></v-spacer><v-btn @click="$emit('close')" color="red lighten-5" small depressed rounded class="red--text">close<v-icon color="red">mdi-close</v-icon></v-btn></v-card-title>
    <v-card-text>

    <v-form
        ref="newClientForm"
        @keyup.native.enter="createClient()"
    >
      <v-row dense>
        <v-col cols="12" sm="12">
          <v-text-field
              v-model="newClient.business_name"
              :disabled="savingProgress"
              :hint="$t('customer.name_hint')"
              :label="$t('customer.name')"
              :rules="requiredRules"
              autofocus
              outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="12">
          <v-text-field
              v-model="newClient.business_phone"
              :disabled="savingProgress"
              :label="$t('customer.contact')"
              outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>

        <v-col cols="12" sm="12">
          <client-categories-component
              :id="category_id"
              @categoryChanged="(id)=>category_id=id"
          ></client-categories-component>
        </v-col>
        <v-col cols="12" sm="12">
          <v-text-field
              v-model="newClient.business_email"
              :disabled="savingProgress"
              :label="$t('customer.email')"
              outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12">
          <v-textarea
              v-model="newClient.business_address"
              :disabled="savingProgress"
              :label="$t('customer.address')"
              auto-grow
              outlined
              rows="2"
          ></v-textarea>
        </v-col>
      </v-row>

    </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="createClient" :loading="savingProgress" block color="blue darken-4" dark rounded depressed large>Save customer</v-btn>
    </v-card-actions>

  </v-card>
</template>
<script>
import ClientCategoriesComponent from "@/components/patials/clientCategoriesComponent.vue";

export default {
  emits:["close","saved"],
  name: "NewCustomerComponent" ,
  components: {ClientCategoriesComponent},
  data(){
    return{
      savingProgress:false,
      category_id:null,
      requiredRules: [(v) => !!v || this.$t("main.required")],

      newClient: {
        business_name: "",
        business_address: "",
        business_phone: "",
        business_email: "",
        business_location: "",
      },
    }
  },
  methods:{
    createClient() {
      if (this.$refs.newClientForm.validate()) {
        this.savingProgress = true;

        const data = {
          category_id:this.category_id,
          business_name:this.newClient.business_name,
          business_address:this.newClient.business_address,
          business_phone:this.newClient.business_phone,
          business_email:this.newClient.business_email,
          business_location:this.newClient.business_location,
          first_name:"",
          last_name:"",
          phone_number:"",
        };

        axios
            .post("/api/createclient", data)
            .then((res) => {
              this.savingProgress = false;
              this.$emit("saved", res.data);

            })
            .catch((err) => {
              this.savingProgress = false;

            });
      }
    },
  }
}
</script>


<style scoped>

</style>