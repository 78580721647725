<template>
  <span>
    <v-subheader>Loans</v-subheader>

    <v-list dense>

            <v-list-item
                color="red"
                v-for="loan in result.items"
                :key="loan.id"
                class="mb-2 text-decoration-none"
                :to="`/accounting/loans/${loan.uuid}`"
            >

        <v-list-item-avatar rounded="lg" size="70" color="red lighten-5">
            <v-icon color="red darken-1">mdi-cash-minus</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Paid Amount:{{ loan.paid_amount | toMoney | currency_symbol }} - Loan Amount:{{ loan.amount | toMoney | currency_symbol }}</v-list-item-title>
          <v-list-item-subtitle>Loan Account: {{ loan.loan_account.account_name }}</v-list-item-subtitle>
          <v-list-item-subtitle>Bank Account: {{ loan.bank_account.account_name }}</v-list-item-subtitle>
          <v-list-item-subtitle>Date: {{ loan.date | humanDate }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action-text>
          <v-icon color="grey lighten-2" small>mdi-chevron-right</v-icon>
        </v-list-item-action-text>
      </v-list-item>


<!--      <v-list-item v-for="loan in result.items" :key="loan.id" class="mb-2">-->
<!--        <div-->
<!--          class="pa-1 w-100 blue-grey&#45;&#45;text"-->
<!--          style="background-color: #eceff1; border-radius: 13px"-->
<!--        >-->
<!--          <v-card-title class="d-flex align-center">-->
<!--            <v-icon class="mr-2" color="blue-grey darken-1"-->
<!--              >mdi-cash-minus</v-icon-->
<!--            >-->
<!--            <a-->
<!--              :href="`/accounting/loans/${loan.uuid}`"-->
<!--              target="_blank"-->
<!--              class="font-weight-bold"-->
<!--              style="font-size: 1rem"-->
<!--            >-->
<!--              {{ loan.paid_amount | toMoney | currency_symbol }} - {{ loan.amount | toMoney | currency_symbol }}-->
<!--            </a>-->
<!--          </v-card-title>-->

<!--          <v-card-subtitle-->
<!--            class="d-flex justify-space-between align-center mt-1"-->
<!--          >-->
<!--            <div class="d-flex flex-column">-->
<!--              <span>-->
<!--                Loan Account:-->
<!--                <b>-->
<!--                  {{ loan.loan_account.account_name }}-->
<!--                </b>-->
<!--              </span>-->
<!--              <span>-->
<!--                Bank Account:-->
<!--                <b>-->
<!--                  {{ loan.bank_account.account_name }}-->
<!--                </b>-->
<!--              </span>-->
<!--              <span>-->
<!--                Date:-->
<!--                <b>-->
<!--                  {{ loan.date | humanDate }}-->
<!--                </b>-->
<!--              </span>-->
<!--            </div>-->
<!--            <v-chip-->
<!--              style="background-color: rgba(255, 0, 0, 0.08)"-->
<!--              class="text-danger font-weight-bolder"-->
<!--              small-->
<!--              v-if="loan.paid_status == 'Not Paid'"-->
<!--            >-->
<!--              {{ $t("main.not_paid") }}-->
<!--            </v-chip>-->

<!--            <v-chip-->
<!--              style="background-color: rgba(255, 255, 0, 0.08)"-->
<!--              class="text-warning font-weight-bolder"-->
<!--              small-->
<!--              v-if="loan.paid_status == 'Paid Part'"-->
<!--            >-->
<!--              {{ $t("main.paid_part") }}-->
<!--            </v-chip>-->

<!--            <v-chip-->
<!--              style="background-color: rgba(0, 128, 0, 0.08)"-->
<!--              class="text-success font-weight-bolder"-->
<!--              small-->
<!--              v-if="loan.paid_status == 'Paid'"-->
<!--            >-->
<!--              {{ $t("main.paid") }}-->
<!--            </v-chip>-->
<!--          </v-card-subtitle>-->
<!--        </div>-->
<!--      </v-list-item>-->
    </v-list>
  </span>
</template>

<script>
export default {
  props: ["result"],
};
</script>
