
const defaultRules = {
    pos: {
        edit: {
            title: "Edit",
            value: true,
            description: "Ability to edit POS transactions"
        },
        create: {
            title: "Create",
            value: true,
            description: "Ability to create POS transactions"
        },
        "delete": {
            title: "Delete",
            value: true,
            description: "Ability to delete POS transactions"
        },
        readonly: {
            title: "Read only amounts",
            value: false,
            description: "Ability to change amounts when creating POS transactions"
        }
    },
    bill: {
        edit: {
            title: "Edit",
            value: true,
            description: "Ability to edit bills"
        },
        create: {
            title: "Create",
            value: true,
            description: "Ability to create bills"
        },
        "delete": {
            title: "Delete",
            value: true,
            description: "Ability to delete bills"
        },
        readonly: {
            title: "Read only amounts",
            value: false,
            description: "Ability to change amounts when creating bills"
        }
    },
    invoice: {
        edit: {
            title: "Edit",
            value: true,
            description: "Ability to edit invoices"
        },
        create: {
            title: "Create",
            value: true,
            description: "Ability to create invoices"
        },
        "delete": {
            title: "Delete",
            value: true,
            description: "Ability to delete invoices"
        },
        readonly: {
            title: "Read only amounts",
            value: false,
            description: "Ability to change amounts when creating invoices"
        }
    },
    inventory: {
        edit: {
            title: "Edit",
            value: true,
            description: "Ability to edit a product or service"
        },
        adjust: {
            title: "Adjust inventory",
            value: true,
            description: "Ability to adjust the inventory of a product or service"
        },
        create: {
            title: "Create",
            value: true,
            description: "Ability to create a product or service"
        },
        "delete": {
            title: "Delete",
            value: true,
            description: "Ability to delete a product or service"
        }
    }
};


 export {
     defaultRules
}






