<template>
  <span>
    <v-subheader>Quotes</v-subheader>

    <v-list dense>

        <v-list-item
            v-for="quote in result.items" :key="quote.id"
            class="mb-2 text-decoration-none rounded-lg"
            :to="`/income/quotes/${quote.id}`"

        >

        <v-list-item-avatar rounded="lg" color="orange lighten-5">

            <v-icon color="orange">mdi-receipt-text</v-icon>

          </v-list-item-avatar>
        <v-list-item-content>

          <v-list-item-title>
              {{ quote.quote_number }} - {{ quote.customer.business_name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            Amount Due: {{ quote.amount_due | toMoney | currency_symbol }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action-text>

          <v-icon color="grey lighten-2" small>mdi-chevron-right</v-icon>

        </v-list-item-action-text>

      </v-list-item>

    </v-list>
  </span>
</template>

<script>
export default {
  props: ["result"],
};
</script>
