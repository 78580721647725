<template>
  <span>
    <v-subheader>Customers</v-subheader>

    <v-list dense>

      <v-list-item
        v-for="customer in result.items"
        :key="customer.id"
        class="mb-2 text-decoration-none rounded-lg"
        :to="`/income/customers/${customer.id}`"
      >
        <v-list-item-action-text>
          <avatar-component :size="60" class="mr-2" rounded="lg" :label="customer.business_name"></avatar-component>
        </v-list-item-action-text>
        <v-list-item-content>
          <v-list-item-title>
             {{ customer.business_name }}  {{ customer.business_email === "" ? "" : " - "+customer.business_email }}
          </v-list-item-title>
          <v-list-item-subtitle>
           Unpaid Amount: {{customer.unformated_balance | toMoney | currency_symbol }}
          </v-list-item-subtitle>
             <v-list-item-subtitle>
          Phone Number: {{customer.phone_number === "" ? "N/A" : customer.phone_number}}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action-text>
          <v-icon color="grey lighten-2" small>mdi-chevron-right</v-icon>
        </v-list-item-action-text>
      </v-list-item>

    </v-list>
  </span>
</template>

<script>
import AvatarComponent from "@/components/agents/avatarComponent.vue";

export default {
  components: {AvatarComponent},
  props: ["result"],
};
</script>
