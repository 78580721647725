<template>

  <div>
    <v-dialog
        v-model="customerDialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        persistent
        scrollable
        width="500"
    >
      <v-card>
        <v-card-title class="pb-0">
          <v-text-field
              v-if="step==1"
              v-model="query"
              :placeholder="$t('main.search')"
              clearable
              prepend-inner-icon="search"
              type="search"
              flat
              class="mt-5"
              rounded
              filled
          ></v-text-field>

          <v-btn v-if="step>1" class="mb-3" color="blue" rounded small text @click="step=1">
            <v-icon>mdi-arrow-left</v-icon>
            Select from list
          </v-btn>
          <v-btn v-if="step==3" class="mb-3" color="blue" rounded small text @click="step=2">

            <v-icon> mdi-plus</v-icon>
            {{ $t("main.new") }}
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn color="red lighten-5 red--text" depressed rounded small @click="$emit('close')">
            {{ $t("main.close") }}
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-title v-if="step==1" class="mb-0">

          <v-btn color="blue" rounded small text @click="step=2">

            <v-icon> mdi-plus</v-icon>
            {{ $t("main.new") }}
          </v-btn>

          <v-btn v-if="bulk" color="blue" rounded small text @click="step=3">

            <v-icon>mdi-account-multiple-plus-outline</v-icon>
            Select Category

          </v-btn>

          <v-spacer></v-spacer>
          <v-chip class="font-weight-light" color="blue lighten-5 black--text">
            <v-icon small>mdi-account-multiple-outline</v-icon>
            {{ filteredCustomers.length }} Customers({{ query ? "per filter " : "total" }})
          </v-chip>
        </v-card-title>

        <!-- CATEGORY SELECTED -->
        <v-card-title v-if="step==3">
          <client-categories-component
              class="w-100"
              @categoryChanged="catSelected"
          ></client-categories-component>
          <h5 class="font-weight-light">Found {{ selectedCustomers.length }} </h5>
          <v-spacer></v-spacer>
          <h5 class="font-weight-light">{{ CustomersSelectedInCategory.length }} Selected</h5>
        </v-card-title>
        <!-- LIMIT ERROR -->
        <v-card-text class="section pa-0" >
          <v-alert class="ma-2" v-if="selectedCustomers.length>100 && step==3" color="error lighten-5 red--text">
            Limit exceeded, you can only invoice a maximum of 100 customers.
          </v-alert>

          <v-stepper v-model="step" class="p-0" style="box-shadow: none">
            <v-stepper-items class="p-0">
              <v-stepper-content class="p-0" step="1">

                <v-virtual-scroll
                    v-if="filteredCustomers.length"
                    :bench="50"
                    :item-height="70"
                    :items="filteredCustomers"
                    max-height="70vh"
                >
                  <template v-slot:default="{ item }">

                    <v-list-item
                        @click="selectCustomer(item)"

                    >
                      <v-list-item-action>
                        <avatar-component :label="item.business_name"></avatar-component>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{
                            item.business_name
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text--disabled">
                          {{
                            item.business_address
                          }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text--disabled">
                          {{
                            item.business_phone
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon class="text--disabled" small>mdi-chevron-right</v-icon>
                      </v-list-item-icon>


                    </v-list-item>

                  </template>

                </v-virtual-scroll>

                <norecords-component v-else
                                     descritpion="You do not have customers yet, create your first customer"
                                     title="You have no customers yet"

                ></norecords-component>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-form
                    ref="clientForm"
                    v-model="formValid"
                    @keyup.native.enter="formValid && createClient($event)"
                >
                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <v-text-field
                          v-model="newClient.business_name"
                          :disabled="savingProgress"
                          :hint="$t('customer.name_hint')"
                          :label="$t('customer.name')"
                          :rules="requiredRules"
                          autofocus
                          outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12">
                      <v-text-field
                          v-model="newClient.business_phone"
                          :disabled="savingProgress"
                          :label="$t('customer.contact')"
                          outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row dense>

                    <v-col cols="12" sm="12">
                      <client-categories-component
                          :id="category_id"
                          @categoryChanged="(id)=>category_id=id"
                      ></client-categories-component>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field
                          v-model="newClient.business_email"
                          :disabled="savingProgress"
                          :label="$t('customer.email')"
                          outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-textarea
                          v-model="newClient.business_address"
                          :disabled="savingProgress"
                          :label="$t('customer.address')"
                          auto-grow
                          outlined
                          rows="2"
                      ></v-textarea>
                    </v-col>
                  </v-row>

                </v-form>


              </v-stepper-content>

              <v-stepper-content class="p-0" step="3">


                <v-list>


                  <v-list-item v-for="customer in selectedCustomers" :key="customer.id"
                               :class="customer.selected ? 'green lighten-5' : ''" class="border-bottom">
                    <v-list-item-action>
                      <avatar-component :label="customer.business_name"></avatar-component>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title :class="customer.selected ? 'green--text' : ''" class="font-weight-light">
                        {{ customer.business_name }}
                      </v-list-item-title>
                      <v-list-item-subtitle :class="customer.selected ? 'green--text' : ''">
                        {{ customer.business_address }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox
                          v-model="customer.selected"
                          color="green"

                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>

                </v-list>

              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-actions>

          <v-btn
              v-if="step==3"
              v-show="CustomersSelectedInCategory.length>1" block color="blue darken-4"
              :dark="CustomersSelectedInCategory.length <= 100"
              depressed
              x-large
              rounded
              @click="selectBulk"
              :disabled="CustomersSelectedInCategory.length > 100"
          >Select {{ CustomersSelectedInCategory.length }} customers
          </v-btn>
          <v-btn
              v-if="step==3"
              v-show="CustomersSelectedInCategory.length===1"
              block
              color="red"
              dark
              x-large
              rounded
              depressed
              @click="step=1"
          >
            <v-icon>mdi-arrow-left</v-icon>
            For 1 customer, select from list
          </v-btn>

          <v-btn
              v-if="step==2"
              :loading="savingProgress"
              block
              color="blue darken-4"
              dark
              depressed
              x-large
              rounded
              @click="createClient"
          >{{ $t("main.save") }} {{ $t("main.customer") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>

</template>

<script>

import ClientCategoriesComponent from "../patials/clientCategoriesComponent";
import AvatarComponent from "../agents/avatarComponent";
import NorecordsComponent from "../sync/norecordsComponent";

export default {
  components: {NorecordsComponent, AvatarComponent, ClientCategoriesComponent},
  props: {
    customers: {
      type: Array,
      default: () => [],
    },
    customerDialog: {
      type: Boolean,
      default: false,
    },
    bulk: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bulkList: [],
      step: 1,
      category_id: null,
      newCustomerDialog: false,
      formValid: false,
      savingProgress: false,
      selectedCustomers: [],
      query: "",
      newClient: {
        business_name: "",
        business_address: "",
        business_phone: "",
        business_email: "",
        business_location: "",
      },
      requiredRules: [(v) => !!v || this.$t("main.required")],
    };
  },

  methods: {
    selectBulk() {
      this.bulkList = this.CustomersSelectedInCategory;
      this.$emit('bulkSelected', this.bulkList);
      this.$emit("select", null);
    },
    catSelected(id) {

      const customerList = JSON.parse(JSON.stringify(this.customers));

      const filter = customerList.filter(customer => {
        return customer.category_id === id;
      });

      let finalList = [];

      filter.forEach(customer => {
        customer.selected = true;
        finalList.push(customer);

      });

      this.selectedCustomers = finalList;

    },

    selectCustomer(customer) {

      this.$emit("select", customer);
      this.$emit('bulkSelected', []);

    },
    resetClient() {
      Object.keys(this.newClient).map((k) => (this.newClient[k] = ""));
    },
    createClient() {
      if (this.$refs.clientForm.validate()) {
        this.savingProgress = true;
        const formdata = new FormData();

        Object.keys(this.newClient).map((k) => {
          formdata.append(k, this.newClient[k]);
        });
        formdata.append('category_id', this.category_id);
        formdata.append("id", this.$store.state.user.user_infor.id);
        formdata.append("first_name", "");
        formdata.append("last_name", "");
        formdata.append("phone_number", "");

        axios
            .post("/api/createclient", formdata)
            .then((res) => {

              this.step = 1;
              this.savingProgress = false;
              this.newCustomerDialog = false;
              this.resetClient();
              this.$emit("select", res.data);
              this.$emit("newCustomer", res.data);
              this.$emit("isSuccess");
              this.$emit(
                  "successMsg",
                  this.$t("main.customer") +
                  "(" +
                  res.data.business_name +
                  ")" +
                  this.$t("main.save_success")
              );
            })
            .catch((err) => {
              this.savingProgress = false;
              // this.$emit("hasError");
              // this.$emit("errorMsg", this.$t("main.client_create_error"));
            });
      }
    },
  },
  getAddressData(addressData, placeResultData, id) {
    if (addressData) {
      this.newClient.business_location =
          addressData.longitude + "," + addressData.latitude;
    }
  },
  computed: {

    currentBusiness() {
      return this.$store.state.user.user_infor.current_business;
    },
    CustomersSelectedInCategory() {
      return this.selectedCustomers.filter(customer => {
        return customer.selected;
      });
    },

    filteredCustomers() {
      if (!this.query) return this.customers.filter(customer=>!customer.archived);

      return this.customers.filter(customer=>!customer.archived)
          .filter((customer) => {
        return customer.business_name
            .toLowerCase()
            .includes(this.query.toLowerCase());
      });
    },
  },
};
</script>
