<template>

  <span>

       <v-subheader>{{result.name}}</v-subheader>
    <v-list dense>

          <v-list-item
              color="blue"
              v-for="item in result.items"
              :key="item.id"
              class="mb-2 text-decoration-none"
              :to="`/settings/openingbalance/${item.id}?itemid=${item.id}`"
          >

        <v-list-item-avatar rounded="lg" size="60" color="blue lighten-5">
            <v-icon color="blue">mdi-anchor</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.description}}</v-list-item-title>
          <v-list-item-subtitle>Created At: {{ item.created_at | humanDateTime }}</v-list-item-subtitle>

        </v-list-item-content>
        <v-list-item-action-text>
          <v-icon color="grey lighten-2" small>mdi-chevron-right</v-icon>
        </v-list-item-action-text>
      </v-list-item>

    </v-list>
  </span>
</template>

<script>
export default {
  props:{
    result:{
      type:Object
    }
  },
  name: "OpeningBalances"
}
</script>


<style scoped>

</style>