<template>
  <span>
    <v-subheader>Budgets</v-subheader>

    <v-list dense>
      <v-list-item
          color="blue"
          v-for="budget in result.items"
          :key="budget.id"
          class="mb-2 text-decoration-none"
          :to="`/accounting/budget/preview/${budget.uuid}`"
      >
        <v-list-item-avatar color="blue lighten-5" rounded="lg">
          <v-icon color="blue">mdi-chart-line</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ budget.name }}
          </v-list-item-title>
          <v-list-item-subtitle>Date: {{ budget.updated_at | humanDate }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </span>
</template>

<script>
export default {
  name: "Budgets",
  props: ["result"],

}
</script>

<style scoped>

</style>