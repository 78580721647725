<template>
  <v-card>
    <v-card-title>Duplicate invoice
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn v-on="on" :to="'/income/invoices/edit/'+originalInvoice.id+'?a=copy'" target="_blank" text color="blue" class="pa-0 mr-2" rounded>Open in editor<v-icon color="blue" small>mdi-open-in-new</v-icon></v-btn>
        </template>
        <span>Open this invoice in the invoice editor for further changes</span>
      </v-tooltip>
      <v-btn @click="$emit('close')" rounded depressed color="red lighten-5" class="red--text">close
        <v-icon color="red" small>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6" :class="{'grey lighten-5':!$vuetify.theme.isDark,'black':$vuetify.theme.isDark}"
               class="rounded-lg">

          <v-divider></v-divider>
          <h4 class="font-weight-black mt-4 text-right">Billed To:</h4>

          <v-list-item class="border rounded-lg">
            <v-list-item-icon>
              <avatar-component rounded="lg" :size="80"
                                :label="originalInvoice.customer.business_name"></avatar-component>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title><v-btn target="_blank" :to="'/income/customers/'+originalInvoice.customer.id" text color="blue" class="pa-0">{{ originalInvoice.customer.business_name }}<v-icon x-small color="blue">mdi-open-in-new</v-icon></v-btn></v-list-item-title>
              <v-list-item-subtitle :title="originalInvoice.customer.business_address"
                                    v-if="originalInvoice.customer.business_address">
                {{ originalInvoice.customer.business_address }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="originalInvoice.customer.business_phone">
                {{ originalInvoice.customer.business_phone }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="originalInvoice.customer.business_email">
                {{ originalInvoice.customer.business_email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
          <h4 class="font-weight-black mt-4 text-right">Invoice Info:</h4>

          <p class="text-right">Invoice #:<strong>{{ originalInvoice.invoice_number }}</strong></p>

          <p class="text-right">Issue Date #:<strong>{{ originalInvoice.start_date | humanDate }}</strong></p>

          <p class="text-right">Due Date #:<strong>{{ originalInvoice.end_date | humanDate }}</strong></p>


          <v-divider></v-divider>
          <h4 class="font-weight-black mt-4 mb-0 text-right">Summaries:</h4>

          <p class="text-right">Sub
            total:<strong>{{ originalInvoice.fx_currency }}{{ originalInvoice.gross_amount |  toMoney }}</strong></p>
          <p class="text-right">
            Discount:<strong>{{ originalInvoice.fx_currency }}{{ originalInvoice.discount_amount |  toMoney }}</strong>
          </p>
          <p class="text-right">Net Amount:<strong>{{ originalInvoice.fx_currency }}{{ netAmount |  toMoney }}</strong>
          </p>
          <p class="text-right">Tax Amount:<strong>{{ originalInvoice.fx_currency }}{{ taxAmount |  toMoney }}</strong>
          </p>
          <p class="text-right">Amount
            Due:<strong>{{ originalInvoice.fx_currency }}{{ originalInvoice.amount_due |  toMoney }}</strong></p>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="12" sm="6">

          <v-form ref="duplicateForm">

            <v-list-item class="border rounded-lg">
              <v-list-item-title>
                <v-switch class="ml-3" v-model="changeCustomer" inset color="blue" label="Change Customer"></v-switch>
              </v-list-item-title>
            </v-list-item>

            <v-autocomplete :rules="store.state.requiredRule"
                            v-model="client_id"
                            item-value="id"
                            item-text="business_name"
                            :loading="loading"
                            v-if="changeCustomer"
                            label="Select Customer"
                            class="mt-4"
                            outlined
                            :items="clients">
              <template v-slot:prepend-item>
                <v-btn @click="showNewCustomerDialog=true" text color="blue" block>Create New</v-btn>
              </template>

            </v-autocomplete>

            <v-divider></v-divider>
            <v-list-item class="border rounded-lg mt-3">
              <v-list-item-title>
                <v-switch v-model="changeTerms" class="ml-3" inset color="blue" label="Change Terms"></v-switch>
              </v-list-item-title>
            </v-list-item>


            <v-menu v-if="changeTerms" v-model="startDate_menu2" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                    :rules="store.state.requiredRule"
                    class="mt-4"
                    v-model="startdate"
                    :label="$t('main.issue_date')"
                    readonly
                    outlined=""
                    v-on="on"></v-text-field>
              </template>
              <v-date-picker no-title :min="cut_off_date()"
                             v-model="startdate"
                             @input="startDate_menu2 = false"></v-date-picker>
            </v-menu>

            <v-menu v-if="changeTerms" v-model="endDate_menu2" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                    :rules="store.state.requiredRule"
                    v-model="enddate"
                    :label="$t('main.due_date')"
                    readonly
                    outlined=""
                    v-on="on"></v-text-field>
              </template>
              <v-date-picker
                  :min="cut_off_date()"
                  no-title
                  v-model="enddate"
                  @input="endDate_menu2 = false"></v-date-picker>
            </v-menu>
          </v-form>

          <v-btn @click="duplicate" :loading="saving" class="mt-5" block depressed color="blue darken-4" dark rounded
                 x-large>Duplicate Invoice
          </v-btn>

          <v-divider  class="mt-7"></v-divider>
          <h2 v-if="duplicates.length>0">{{duplicates.length}}{{duplicates.length>1?'Copies':'Copy'}}</h2>
          <v-list>
            <v-list-item :to="'/income/invoices/'+item.id" target="_blank" v-for="item in duplicates" :key="item.id" class="border text-decoration-none">
              <v-list-item-content>
                <v-list-item-title>#{{ item.invoice_number }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>mdi-open-in-new</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

    </v-card-text>


    <v-dialog width="500" v-model="showNewCustomerDialog">
     <new-customer-component @close="showNewCustomerDialog=false" @saved="clientSaved"></new-customer-component>
    </v-dialog>

    <v-snackbar color="green" v-model="show_success">{{ success_message }}</v-snackbar>
  </v-card>
</template>

<script>
import AvatarComponent from "@/components/agents/avatarComponent.vue";
import {store} from "@/store";
import {cut_off_date} from "@/utils";
import moment from "moment";
import CustomerSelect from "@/components/invoices/customerSelect.vue";
import NewCustomerComponent from "@/components/agents/NewCustomerComponent.vue";

export default {
  components: {NewCustomerComponent, CustomerSelect, AvatarComponent},
  props: {
    originalInvoice: {
      type: Object
    }
  },
  emits: ["close", "saved"],
  name: "DuplicateComponent",
  data() {
    return {
      showNewCustomerDialog:false,
      changeCustomer: false,
      changeTerms: false,
      clients: [],
      loading: false,
      client_id: null,
      startdate: "",
      enddate: "",
      startDate_menu2: false,
      endDate_menu2: false,
      saving: false,
      success_message: "",
      show_success: false,
      duplicates: []
    }
  },
  computed: {
    store() {
      return store
    },
    netAmount() {

      return this.originalInvoice.gross_amount - this.originalInvoice.discount_amount;

    },
    taxAmount() {
      return this.originalInvoice.amount_due - this.netAmount;
    }
  },
  watch: {
    changeCustomer() {
      if (this.changeCustomer) {
        this.getClients();
      }
    },
    originalInvoice() {

      const issueDate = moment(this.originalInvoice.start_date);
      const dueDate = moment(this.originalInvoice.end_date);

      const termsInDays = dueDate.diff(issueDate, "days");

      this.startdate = moment().format("Y-MM-D");

      this.enddate = moment().add(termsInDays, "days").format("Y-MM-D");

    }
  },
  methods: {
    cut_off_date,
    clientSaved(client){

      this.clients.push(client);
      this.client_id=client.id;
      this.showNewCustomerDialog=false;
    },
    duplicate() {

      if (this.$refs.duplicateForm.validate()) {

        this.saving = true;

        const data = {
          invoice_id: this.originalInvoice.id
        };

        if (this.changeCustomer) {
          data.customer_id = this.client_id;
        }

        if (this.changeTerms) {

          data.issue_date = this.startdate;
          data.due_date = this.enddate;
        }


        axios.post("/api/invoice/duplicate", data)
            .then(res => {

              console.log(res.data);
              this.duplicates.push(res.data);

              this.$emit("saved", res.data);

              this.saving = false;
              this.success_message = "Duplicate saved";
              this.show_success = true;

            })
            .catch(error => {
              this.saving = false;
            })

      }

    },
    getClients() {
      this.loading = true;
      axios.get("/api/getclients")
          .then(res => {
            this.clients = res.data;
            this.loading = false;

          })
    }
  },
  mounted() {
    const issueDate = moment(this.originalInvoice.start_date);
    const dueDate = moment(this.originalInvoice.end_date);

    const termsInDays = dueDate.diff(issueDate, "days");

    this.startdate = moment().format("Y-MM-D");

    this.enddate = moment().add(termsInDays, "days").format("Y-MM-D");


  }
}
</script>

<style scoped>

</style>