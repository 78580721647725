<template>
  <span>
    <v-subheader>Quick Sales</v-subheader>

    <v-list dense>

         <v-list-item
             color="green"
             v-for="sale in result.items"
             :key="sale.id"
             class="mb-2 text-decoration-none"
             :to="`/income/sales/${sale.id}`"
         >

        <v-list-item-avatar rounded="lg" color="green lighten-5">
            <v-icon color="green darken-1">mdi-cash-minus</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title> {{ sale.sales_number }} - {{ sale.server_name }}</v-list-item-title>
          <v-list-item-subtitle>Date:{{ sale.entry_date | humanDate }}</v-list-item-subtitle>
          <v-list-item-subtitle>Amount: {{ sale.amount_due | toMoney | currency_symbol }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action-text>
          <v-icon color="grey lighten-2" small>mdi-chevron-right</v-icon>
        </v-list-item-action-text>
      </v-list-item>

    </v-list>
  </span>
</template>

<script>
export default {
  props: ["result"],
};
</script>
