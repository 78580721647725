<template>
  <span>
    <v-subheader>Suppliers</v-subheader>

    <v-list dense>

       <v-list-item
           v-for="supplier in result.items"
           :key="supplier.id"
           class="mb-2 text-decoration-none rounded-lg"
           :to="`/expense/suppliers/${supplier.id}`"
       >
        <v-list-item-action-text>
          <avatar-component :size="60" class="mr-2" rounded="lg" :label="supplier.supplier_name"></avatar-component>
        </v-list-item-action-text>
        <v-list-item-content>
          <v-list-item-title>
             {{ supplier.supplier_name }} {{supplier.supplier_email ? "-" + supplier.supplier_email : "" }}
          </v-list-item-title>
          <v-list-item-subtitle>
           Unpaid Amount: {{supplier.unformated_balance | toMoney | currency_symbol}}
          </v-list-item-subtitle>
             <v-list-item-subtitle>
          Phone Number: {{supplier.supplier_phone === "" ? "N/A" : supplier.supplier_phone}}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action-text>
          <v-icon color="grey lighten-2" small>mdi-chevron-right</v-icon>
        </v-list-item-action-text>
      </v-list-item>

    </v-list>
  </span>
</template>

<script>
import AvatarComponent from "@/components/agents/avatarComponent.vue";

export default {
  components: {AvatarComponent},
  props: ["result"],
};
</script>
