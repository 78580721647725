<template>
  <span>
    <v-subheader>Deposits</v-subheader>

    <v-list dense>
      <v-list-item
          v-for="invoice in result.items"
          :key="invoice.id"
          class="mb-2 text-decoration-none rounded-lg"
          :to="`/income/deposits/?deposit_id=${invoice.id}`"
      >

        <v-list-item-avatar size="50" rounded="lg" color="blue lighten-5">
            <v-icon color="blue">mdi-receipt-text</v-icon>
          </v-list-item-avatar>
        <v-list-item-content>

          <v-list-item-title>
            Amount: {{ invoice.amount | toMoney | currency_symbol}}
          </v-list-item-title>
          <v-list-item-title>
             Customer: {{ invoice.customer.business_name }}
          </v-list-item-title>
          <v-list-item-subtitle>Date: {{invoice.date | humanDate}}</v-list-item-subtitle>

        </v-list-item-content>
        <v-list-item-action-text>
             <v-chip
                 class="text-danger font-weight-bolder red lighten-5"
                 small
                 v-if="invoice.type.toLowerCase() == 'debit'"
             >
              {{ invoice.type }}
            </v-chip>

            <v-chip
                class="text-success font-weight-bolder green lighten-5"
                small
                v-if="invoice.type.toLowerCase() == 'credit'"
            >
              {{ invoice.type }}
            </v-chip>
          <v-icon color="grey lighten-2" small>mdi-chevron-right</v-icon>

        </v-list-item-action-text>

      </v-list-item>
    </v-list>
  </span>
</template>

<script>
export default {
  props: {
    result:{
      type:Object
    }
  },
  name: "Deposits"
}
</script>

<style scoped>

</style>