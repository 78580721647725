<template>
  <span>
    <v-subheader>Sync Incomes</v-subheader>

    <v-list dense>


        <v-list-item
            color="green"
            v-for="sync in result.items"
            :key="sync.id"
            class="mb-2 text-decoration-none"
            :to="`/accounting/sync/income/${sync.id}?syncid=${sync.id}`"
        >

        <v-list-item-avatar rounded="lg" size="60" color="green lighten-5">
            <v-icon color="green darken-1">mdi-cash-plus</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ sync.total_amount | toMoney | currency_symbol }} - {{ sync.description}}</v-list-item-title>
          <v-list-item-subtitle>Created At: {{ sync.created_at | humanDateTime }}</v-list-item-subtitle>
          <v-list-item-subtitle>Number of transactions: {{ sync.record_count }}</v-list-item-subtitle>

        </v-list-item-content>
        <v-list-item-action-text>
          <v-icon color="grey lighten-2" small>mdi-chevron-right</v-icon>
        </v-list-item-action-text>
      </v-list-item>




<!--      <v-list-item v-for="sync in result.items" :key="sync.id" class="mb-2">-->
<!--        <div-->
<!--          class="pa-1 w-100 light-green&#45;&#45;text"-->
<!--          style="background-color: #f1f8e9; border-radius: 13px"-->
<!--        >-->
<!--          <v-card-title class="d-flex align-center">-->
<!--            <v-icon class="mr-2" color="light-green darken-1">mdi-sync</v-icon>-->
<!--            <a-->
<!--              :href="`/accounting/sync/expense/${sync.id}?syncid=${sync.id}`"-->
<!--              target="_blank"-->
<!--              class="font-weight-bold"-->
<!--              style="font-size: 1rem"-->
<!--            >-->
<!--              {{ sync.total_amount | toMoney | currency_symbol }} - {{ sync.description.slice(0, 30) }}-->
<!--            </a>-->
<!--          </v-card-title>-->

<!--          <v-card-subtitle-->
<!--            class="d-flex justify-space-between align-center mt-1"-->
<!--          >-->
<!--            <div class="d-flex flex-column">-->
<!--              <span>-->
<!--                Created On:-->
<!--                <b>-->
<!--                  {{ sync.created_at | humanDate }}-->
<!--                </b>-->
<!--              </span>-->
<!--            </div>-->
<!--            <v-chip-->
<!--              style="background-color: #dcedc8"-->
<!--              class="light-green&#45;&#45;text font-weight-bolder"-->
<!--              small-->
<!--              >{{ sync.record_count }} Incomes</v-chip-->
<!--            >-->
<!--          </v-card-subtitle>-->
<!--        </div>-->
<!--      </v-list-item>-->
    </v-list>
  </span>
</template>

<script>
export default {
  props: ["result"],
};
</script>
