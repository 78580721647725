<template>
  <span>
    <v-subheader>Bank Transfers</v-subheader>

    <v-list dense>

        <v-list-item
            color="blue"
            v-for="transfer in result.items"
            :key="transfer.id"
            class="mb-2 text-decoration-none"
            :to="`/accounting/banktransfer/${transfer.id}?item=${transfer.id}`"
        >

        <v-list-item-avatar rounded="lg" size="70" color="blue lighten-5">
            <v-icon color="blue darken-1">mdi-cash-minus</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title> {{ transfer.amount | toMoney | currency_symbol }} - {{ transfer.created_at | humanDate }}</v-list-item-title>
          <v-list-item-subtitle>From:{{ transfer.from_account.account_name }},  To:{{ transfer.to_account.account_name }}</v-list-item-subtitle>
          <v-list-item-subtitle>Date:{{ transfer.entry_date | humanDate }}</v-list-item-subtitle>
          <v-list-item-subtitle>Amount: {{ transfer.amount | toMoney | currency_symbol }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action-text>
          <v-icon color="grey lighten-2" small>mdi-chevron-right</v-icon>
        </v-list-item-action-text>
      </v-list-item>

<!--      -->
<!--      <v-list-item-->
<!--        v-for="transfer in result.items"-->
<!--        :key="transfer.id"-->
<!--        class="mb-2"-->
<!--      >-->
<!--        <div-->
<!--          class="pa-1 w-100 green&#45;&#45;text"-->
<!--          style="background-color: #e8f5e9; border-radius: 13px"-->
<!--        >-->
<!--          <v-card-title class="d-flex align-center">-->
<!--            <v-icon class="mr-2" color="light-green darken-1"-->
<!--              >mdi-cash-plus</v-icon-->
<!--            >-->
<!--            <a-->
<!--              :href="`/accounting/banktransfer/${transfer.id}?item=${transfer.id}`"-->
<!--              target="_blank"-->
<!--              class="font-weight-bold"-->
<!--              style="font-size: 1rem"-->
<!--            >-->
<!--              {{ transfer.amount | toMoney | currency_symbol }} - {{ transfer.created_at | humanDate }}-->
<!--            </a>-->
<!--          </v-card-title>-->

<!--          <v-card-subtitle-->
<!--            style="display: flex; flex-direction: column; align-items: start"-->
<!--          >-->
<!--            <span class="d-flex align-center">-->
<!--              From:-->
<!--              <b>{{ transfer.from_account.account_name }}</b>-->
<!--            </span>-->
<!--            <span class="d-flex align-center">-->
<!--              To:-->
<!--              <b>{{ transfer.to_account.account_name }}</b>-->
<!--            </span>-->
<!--          </v-card-subtitle>-->
<!--        </div>-->
<!--      </v-list-item>-->
<!--      -->
<!--      -->

    </v-list>
  </span>
</template>

<script>
export default {
  props: ["result"],
};
</script>
