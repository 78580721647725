import { store } from "./store";
import axios from "axios";
import { getAccessToken } from "./utils";

window._ = require("lodash");

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = axios;


 window.axios.defaults.baseURL = store.state.baseURL;


// window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
// window.axios.defaults.headers.common["Content-Type"] = "application/json";

window.axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  "Content-Type": "application/json",
};

window.axios.defaults.headers.get["Accept"] = "application/json";
window.axios.defaults.headers.post["Accept"] = "application/json";
// window.axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data 8e73f399

    let access_token = getAccessToken();
    if (!access_token) {
      if (
        window.location.pathname !== "/auth/login" &&
        window.location.pathname !== "/auth/signup" &&
        window.location.pathname !== "/init"
      ) {
        window.location = "/auth/login";
      }
    }
    return response;
  },
  function (error) {
    // if (typeof error.response === "undefined") {
    //   if (
    //     window.location.pathname !== "/auth/login" &&
    //     window.location.pathname !== "/auth/signup"
    //   ) {
    //     window.location = "/auth/login";
    //   }
    // }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // eslint-disable-next-line no-console
    if (error.response.status === 401) {
      if (
        window.location.pathname !== "/auth/login" &&
        window.location.pathname !== "/auth/signup" &&
        window.location.pathname !== "/init"
      ) {
        // if (store.state.user && store.state.user.user_infor){
        //   if (!store.state.idle_dialog) {
        //     store.state.idle_dialog = true;
        //   }
        // }else {
        window.location = "/auth/login";
        // }
      }
    }

    else if (error.response.status === 302) {
      store.state.generalMessage = {
        message: error.response.data,
        errors: {},
      };

      store.state.showGeneralMessage = false;
      store.state.showGeneralMessage = true;
    }

    /**
     * Automatically throw 422 errors
     */
    //
    else if (error.response.status === 422) {
      store.state.generalMessage = error.response.data;
      store.state.showGeneralMessage = false;
      store.state.showGeneralMessage = true;
    }

    /**
     * subscription guard forbidden from backend
     */

    else if (error.response.status === 403) {
      store.state.subscriptionForbiddenMessage = error.response.data.message;
      store.state.subscriptionForbidden = false;
      store.state.subscriptionForbidden = true;
    }

    else{
      store.state.generalMessage = {message: "Something went wrong. Could not complete action"};
      store.state.showGeneralMessage = false;
      store.state.showGeneralMessage = true;
    }

    return Promise.reject(error);
  }
);

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// window.Pusher = require("pusher-js");
//
// window.Echo = new Echo({
//   broadcaster: "pusher",
//   key: "1c6d43ee4770d34a4afa",
//   cluster: "eu",
//   namespace: "",
//   encrypted: true,
//   authEndpoint: "https://web.builtaccounting.com/broadcasting/auth",
//   auth: {
//     headers: {
//       Authorization: "Bearer " + getAccessToken(),
//     },
//   },
// });
