<template>
<span>

  <v-btn
      v-if="business && (days<=10 && days>0) && plan"
      class="pl-1"
      color="amber lighten-5 amber--text"
      depressed
      large
      rounded
      @click="showDetails=true"
  >
    <span class="pa-1 ml-0" style="background-color: white!important; border-radius: 50%;">
      <v-progress-circular :value="progressValue" color="amber darken-3" width="2">
        <small class="text-small text-center" style="font-size: 7px">
         {{ days }}<br>
          Days
        </small>
      </v-progress-circular>
    </span>
    <span v-if="!$vuetify.breakpoint.mobile">
   To Expire <v-icon class="bell" large>mdi-bell-outline</v-icon>
    </span>
  </v-btn>
<v-dialog v-model="showDetails" width="500px">
  <v-card color="amber lighten-5" flat style="border-radius: 25px">
    <v-card-title>
<v-spacer></v-spacer>
      <v-btn color="amber" depressed outlined rounded @click="showDetails=false; step=1">close<v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>
    <v-card-text class="text-center">

      <v-stepper elevation="0" class="rounded-xl" v-model="step">

        <v-stepper-content step="1">


      <v-icon class="bell" color="amber" x-large>mdi-bell-outline</v-icon>

<h1 class="font-weight-light display-1 amber--text">{{ days }} Days Left</h1>
      <p>
        Your subscription will expire in <strong> {{ days }} days</strong> , this is a reminder to prepare for renewal of your subscription when it expires.
      </p>


      <v-list class="text-left" style="border-radius: 15px; opacity: 0.7" v-if="plan">

        <v-list-item class="border-bottom">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">{{ plan.planname }}</v-list-item-title>
            <v-list-item-subtitle class="text-small">Plan Name</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="border-bottom">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">{{ plan.start_date | humanDate }}</v-list-item-title>
            <v-list-item-subtitle class="text-small">Start Date</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="border-bottom">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">{{ plan.end_date | humanDate }}</v-list-item-title>
            <v-list-item-subtitle class="text-small">End Date</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="border-bottom">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">{{ plan.amount | toMoney }}</v-list-item-title>
            <v-list-item-subtitle class="text-small">Amount</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="border-bottom">
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">

<!--              <v-btn-->
<!--                  color="green"-->
<!--                  dark-->
<!--                  x-large-->
<!--                  rounded-->
<!--                  depressed-->
<!--                  block-->
<!--                  @click="step=2"-->
<!--              >Extend Subscription<v-icon dark>mdi-arrow-right</v-icon></v-btn>-->
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
        </v-stepper-content>

        <v-stepper-content step="2">

          <v-alert>
            <h2>Current Plan:<strong> {{plan.planname}}</strong></h2>
            <p>Started Date:<strong>{{plan.start_date | humanDate}}</strong></p>
            <p>End Date:<strong>{{plan.end_date | humanDate}}</strong></p>
          </v-alert>

          <h3>Extend Plan:</h3>

          <v-autocomplete
              v-model="billing_cycle"
              label="Cycle" :items="billing_cycles" outlined></v-autocomplete>
        </v-stepper-content>
      </v-stepper>

    </v-card-text>
  </v-card>
</v-dialog>
</span>
</template>

<script>
import moment from "moment";

export default {
  name: "subscriptionAlertComponent",
  data() {
    return {
      showDetails: false,
      step:1,
      billing_cycle: 3,
      billing_cycles: [
        {
          text: "Every three months",
          value: 3,
        },
        {
          text: "Every four months",
          value: 4,
        },
        {
          text: "Every five months",
          value: 5,
        },
        {
          text: "Every six months",
          value: 6,
        },
        {
          text: "Every seven months",
          value: 7,
        },
        {
          text: "Every eight months",
          value: 8,
        },
        {
          text: "Every nine months",
          value: 9,
        },
        {
          text: "Every ten months",
          value: 10,
        },
        {
          text: "Every eleven months",
          value: 11,
        },
        {
          text: "Annually",
          value: 12,
        },
        {
          text: "Every two years",
          value: 24,
        },
        {
          text: "Every three years",
          value: 36,
        },
        {
          text: "Every four years",
          value: 48,
        },
      ],

    }
  },
  computed: {
    progressValue() {
      return (this.days / 10) * 100;
    },

    user() {
      return this.$store.state.user.user_infor;
    },
    business() {
      return this.user?.current_business;
    },
    plan() {

      return this.business?.subscription.plan;

    },

    days() {

      if (this.business && this.plan) {
        const endDate = moment(this.plan.end_date);

        const today = moment();

        return endDate.diff(today, 'days');
      }

      return 0;

    }


  }
}
</script>

<style>

@keyframes bellshake {
  0% {
    transform: rotate(0);
  }
  15% {
    transform: rotate(5deg);
  }
  30% {
    transform: rotate(-5deg);
  }
  45% {
    transform: rotate(4deg);
  }
  60% {
    transform: rotate(-4deg);
  }
  75% {
    transform: rotate(2deg);
  }
  85% {
    transform: rotate(-2deg);
  }
  92% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0);
  }
}

.bell {
  animation: bellshake .5s ease-in-out infinite;
  backface-visibility: hidden;
  transform-origin: top right;

}

</style>