<template>
  <span>

    <v-subheader>{{result.name}}</v-subheader>
    <v-list dense>
      <v-list-item color="blue" :to="`/accounting/tag/${tag.name}`" v-for="tag in result.items" :key="tag.id" class="mb-2 text-decoration-none">
         <v-list-item-avatar rounded="lg" size="60" color="blue lighten-5">
            <v-icon color="blue darken-">mdi-tag-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>

          <v-list-item-title>{{ tag.name }}</v-list-item-title>
          <v-list-item-subtitle>Created At  {{ tag.created_at | humanDateTime }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action-text>
          <v-icon small color="grey">mdi-chevron-right</v-icon>
        </v-list-item-action-text>
      </v-list-item>
    </v-list>
  </span>
</template>

<script>
export default {
  props: ["result"],
};
</script>
