<template>
  <span>

    <v-subheader>{{result.name}}</v-subheader>
    <v-list dense>

          <v-list-item
              color="red"
              v-for="sync in result.items"
              :key="sync.id"
              class="mb-2 text-decoration-none"
              :to="`/accounting/sync/expense/${sync.id}?syncid=${sync.id}`"
          >

        <v-list-item-avatar rounded="lg" size="60" color="red lighten-5">
            <v-icon color="red darken-1">mdi-cash-minus</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ sync.total_amount | toMoney | currency_symbol }} - {{ sync.description}}</v-list-item-title>
          <v-list-item-subtitle>Created At: {{ sync.created_at | humanDateTime }}</v-list-item-subtitle>
          <v-list-item-subtitle>Number of transactions: {{ sync.record_count }}</v-list-item-subtitle>

        </v-list-item-content>
        <v-list-item-action-text>
          <v-icon color="grey lighten-2" small>mdi-chevron-right</v-icon>
        </v-list-item-action-text>
      </v-list-item>

    </v-list>
  </span>
</template>

<script>
export default {
  props: ["result"],
};
</script>
