<template>
  <span>
    <v-col style="height: 90px" cols="10" v-if="!$vuetify.breakpoint.mobile">
      <v-text-field
        clearable
        filled
        rounded
        type="search"
        class="mt-2"
        placeholder="Search for anything [Ctrl + B]..."
        @click="showDialog = true"
        readonly
        id="AppSearchBox"
        v-if="!$vuetify.breakpoint.mobile"
      >
        <template v-slot:prepend-inner>
          <v-badge color="error" overlap dot :value="hasResults">
            <v-icon>search</v-icon>
          </v-badge>
        </template>
      </v-text-field>
    </v-col>

    <v-btn v-if="$vuetify.breakpoint.mobile" icon @click="showDialog = true"
      ><v-icon>search</v-icon></v-btn
    >
    <v-dialog
      transition="slide-y-transition"
      width="80%"
      v-model="showDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="m-0">
          <v-text-field
            clearable
            filled
            rounded
            type="search"
            prepend-inner-icon="search"
            placeholder="Search for anything..."
            class="mt-6"
            autofocus
            :loading="searching"
            @input="debounceSearch"
            v-model="keyword"
            @click:clear="
              results = null;
              searching = false;
              hasResults = false;
            "
            :hint="
              $vuetify.breakpoint.mobile
                ? ''
                : 'Search for things like customers,suppliers,invoice numbers,accounts etc...'
            "
          >
          </v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            rounded
            small
            color="red lighten-5 red--text"
            @click="showDialog = false"
            >{{ $t("main.close") }}<v-icon small>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <center v-if="!results">
            <v-img
              src="/img/icons/search.svg"
              width="150px"
              class="m-3"
            ></v-img>
            <h4>You have not searched for anything yet</h4>
            <h2 class="font-weight-black">
              Let's find what you are looking for!
            </h2>
          </center>
          <search-results
            @closeDialog="showDialog = false"
            v-else
            :results="results"
          ></search-results>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import SearchResults from "./SearchResults";
import axios from "axios";

export default {
  name: "Search",
  components: { SearchResults },
  data() {
    return {
      keyword: "",
      showDialog: false,
      results: null,
      searching: false,
      axiosSource: null,
      hasResults: false,
    };
  },
  mounted() {
    this.axiosSource = axios.CancelToken.source();
    window.addEventListener("keydown", this.handleKeyDown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    debounceSearch() {
      if (this.keyword && this.keyword.length > 0) {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.axiosSource.cancel();
          this.search();
        }, 1000);
      } else {
        this.searching = false;
        this.results = null;
        this.hasResults = false;
      }
    },
    search() {
      this.searching = true;
      axios.get(`/api/search/${this.keyword}`).then((res) => {
        this.results = res.data;
        this.hasResults = this.results.length > 0;
        this.searching = false;
      });
    },
    toggleSearchDialog() {
      this.showDialog = !this.showDialog;
    },
    handleKeyDown(event) {
      if (event.ctrlKey && event.key === "b") {
        event.preventDefault();
        this.toggleSearchDialog();
      }
    },
  },
};
</script>

<style>
div#AppSearchBox .v-input__control {
  height: 50px !important;
  background-color: red;
}
</style>
