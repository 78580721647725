<template>
  <div>
    <v-autocomplete
      :outlined="outline"
      :loading="loading"
      :label="label"
      :items="categories"
      :flat="flat"
      :rounded="rounded"
      item-text="name"
      item-value="id"
      v-model="category_id"
      @change="setID()"
      :filled="filled"
      :dark="dark"
      :clearable="clearable"
      @click:clear="$emit('reset')"
    >
      <template v-slot:prepend-item>
        <v-btn
          @click="addDialog = true"
          text
          color="blue"
          class="mt-2 mb-2"
          block
          ><v-icon>mdi-plus</v-icon>New</v-btn
        >
      </template>
    </v-autocomplete>

    <v-dialog persistent width="500" v-model="addDialog">
      <v-card>
        <v-card-title class="font-weight-light">
          Create a new customer category <v-spacer></v-spacer>
          <v-btn
            color="red lighten-5 red--text"
            rounded
            depressed
            @click="addDialog = false"
            small
            >Close <v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-form @keypress.native.enter="saveCategory" ref="categoryForm">
            <v-text-field
              outlined
              :label="$t('main.name')"
              v-model="name"
              :rules="nameRules"
            ></v-text-field>

            <v-textarea
              rows="2"
              auto-grow
              outlined
              :label="$t('main.description')"
              v-model="description"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :loading="progress"
            @click="saveCategory"
            color="blue darken-4"
            depressed
            rounded
            block
            large
            dark
            >{{ $t("main.save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar dark v-model="success">
      {{ success_message }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    initEmpty: {
      type: Boolean,
      default: false,
    },
    filled:{
      type:Boolean,
      default:false
    },
    outline:{
      type:Boolean,
      default:true
    },
    flat:{
      type:Boolean,
      default:false
    },
    rounded:{
      type:Boolean,
      default:false
    },
    label: {
      type: String,
      default: "Category",
    },
  },

  name: "clientCategoriesComponent",
  data() {
    return {
      addDialog: false,
      loading: false,
      categories: [],
      name: "",
      description: "",
      nameRules: [
        (v) => !!v || this.$t("main.required"),
        //v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      category_id: null,
      progress: false,
      success: false,
      success_message: "",
    };
  },
  watch: {
    id() {
      this.category_id = this.id;
    },
  },
  methods: {
    setID() {
      this.sendId(this.category_id);
    },

    sendId(id) {
      this.$emit("categoryChanged", id);
    },

    getCategories() {
      this.loading = true;
      axios.get("/api/clients/categories").then((res) => {
        this.categories = res.data;
        if (this.categories.length) {
          if (!this.initEmpty) {
            this.category_id = this.id ? this.id : this.categories[0].id;
          }
          this.sendId(this.category_id);
        }
        this.loading = false;
      });
    },

    saveCategory() {
      if (this.$refs.categoryForm.validate()) {
        this.progress = true;
        let formdata = new FormData();

        formdata.append("name", this.name);
        formdata.append("description", this.description);

        axios
          .post("/api/clients/category", formdata)
          .then((res) => {
            this.category_id = res.data.id;
            this.sendId(this.category_id);
            this.categories.push(res.data);
            this.progress = false;
            this.addDialog = false;
            this.success_message = "Customer category created";
            this.success = true;
          })
          .catch((error) => {
            this.progress = false;
          });
      }
    },
  },
  mounted() {
    this.getCategories();

    if (this.id) {
      this.category_id = this.id;
    }
  },
};
</script>

<style scoped></style>